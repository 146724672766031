<template>
  <div>
    <div id="goldprice"></div>
  </div>
</template>
<style lang="scss" scoped="true">
#goldprice {
  height:550px;
}

</style>
<script>
import HighCharts from "highcharts";

export default {
	props:{
		infoData:Object
	},
  data() {
    return {
      id: "goldprice",
	  switchInfoData:this.infoData
    };
  },
	mounted(){
		this.getData(this.infoData)
	},
computed:{
	switchInfo:function(){
		return this.switchInfoData   //// 监听switchInfoData 的变化
	}
},
watch: {
	// watch监听props里status的变化，然后执行操作
    infoData: {
		handler: function(newVal,oldVal){
		 this.switchInfoData = newVal
		 this.getData(this.switchInfoData)
		},
		 deep: true
    }
  },
  methods: {
	getData(item){
		console.log(item);
		this.initPirce(item)	
	},
    initPirce(item) {
      HighCharts.chart(this.id, {
        chart: {
		spacing : [-40, 0 , 10, 0],
	},
	title: {
		floating:true,
		text: `总押金<br />  ${item.balance}`,
        style: {
            // 文字内容相关样式
            color: "#242424",
            fontSize:'14px'
          },
	},
        subtitle: {
          text: document.ontouchstart === undefined ? "" : ""
        },
        credits: {
          enabled: false
        },
	plotOptions: {
		pie: {
			allowPointSelect: false,
			cursor: 'pointer',
			dataLabels: {
				enabled: true,
                //  
				format: '<b>{point.name}</b>',     
				style: {
					color: '#666666',
				}
			},
			states: {
				hover: {
					enabled: false
				}  
			},
			slicedOffset:0,
			point: {
				events: {
					// 默认是点击突出，这里屏蔽掉
					click: function() {
							return false;

					}
				}
			},
		}
	},
	colors:['#33A5FF','#39394D'],
	series: [{
		type: 'pie',
		size:'56%',
		innerSize: '80%',
		name: '市场额',
		dataLabels:{
			softConnector:true,
			distance: 16, // 数据标签与扇区距离
			onnectorPadding: 0,  // 数据标签与连接线的距离
			formatter: function () {
                    // 通过函数判断是否显示数据标签，为了防止数据标签过于密集
                    return this.y > 1 ? '<b>' + this.point.name + ':</b> ' + this.y + '%'  : null;
                }
		},
		data: [
			['占用押金',item.occupy],
		    ['可退押金',item.refund],
		],
	}]
}, function(c) { // 图表初始化完毕后的会掉函数
	// 环形图圆心
	var centerY = c.series[0].center[1],
		// titleHeight = parseInt(c.title.styles.fontSize);
		titleHeight = 0;
	// 动态设置标题位置
	c.setTitle({
		y:centerY + titleHeight/2
	});
      });
    }
  }
};
</script>
